import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
const NotFoundPage = () => (
  <Layout>
    <Seo title="404 - This page cannot be found" />
    <div className="step-container">
      <div className="step-wrapper">
        <div className="step-full">
          <h1>404</h1>
          <p className="l-body-regular">
            Click <Link to="/">here</Link> to start a Home Care Package Estimate
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
